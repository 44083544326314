#root {
  height: 100%;
}

.scrollable-overflow {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: calc(100vw - 150px);
}

.max-stop-overflow {
  overflow-y: hidden;
  max-height: 100vh;
}

.white-text {
  color: white !important;
}

.activity-feed {
  position: fixed;
  bottom: 0;
  padding-bottom: 1em;
  width: 100%;
}

.feed-format {
  padding-left: 1rem;
}

.float-right {
  float: right;
}
